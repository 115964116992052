import * as React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
      <Layout>
        <div id="text-wrapper">
          <h1 className="center">A Bestiary of Strange Women</h1>
          <p><em>Please read <Link to="colophon">the content warning</Link> before viewing.</em></p>
          <p>Last Updated: <Link to="updatelog">December 29, 2022</Link></p>
          <h2>Deities and Spirits</h2>
          <ul className="toc">
            <li className="hidden"><Link to="">Agate Spirit</Link></li>
            <li className="hidden"><Link to="">Air Spirit</Link></li>
            <li><Link to="bookburner">The Book-Burner</Link></li>
            <li><Link to="deathgod-typei">Death God, type I</Link> <span className="new">new</span></li>
            <li className="hidden"><Link to="">Earth Spirit, type I</Link></li>
            <li className="hidden"><Link to="">Earth Spirit, type II</Link></li>
            <li className="hidden"><Link to="">Fire Spirit</Link></li>
            <li className="hidden"><Link to="">Firefly Spirit</Link></li>
            <li className="hidden"><Link to="">Forest Spirit, type I</Link></li>
            <li><Link to="forestspirit-typeii">Forest Spirit, type II</Link></li>
            <li className="hidden"><Link to="">Forest Spirit, type III</Link></li>
            <li className="hidden"><Link to="">Frostbeard</Link></li>
            <li className="hidden"><Link to="">Grass Jewel Spirit</Link></li>
            <li className="hidden"><Link to="">The Hydra (the Sun God)</Link></li>
            <li><Link to="librarian">The Librarian</Link></li>
            <li><Link to="monarchbutterfly">Monarch Butterfly Spirit</Link></li>
            <li><Link to="nightgod-typei">Night God, type I</Link></li>
            <li className="hidden"><Link to="">Night God, types II and III</Link></li>
            <li className="hidden"><Link to="">Shape-Shifter</Link></li>
            <li><Link to="strangemelancholyqueenwoods">The Strange and Melancholy Queen of the Woods</Link></li>
            <li className="hidden"><Link to="">Water Spirit</Link></li>
          </ul>

          <h2>Transformations</h2>
          <ul className="toc">
            <li className="hidden"><Link to="">Aches</Link></li>
            <li className="hidden"><Link to="">The Botanist</Link></li>
            <li className="hidden"><Link to="">Euphoric Dissolution</Link></li>
            <li className="hidden"><Link to="">Heart Ejection</Link></li>
            <li className="hidden"><Link to="">Impersonal Interpersonal</Link></li>
            <li className="hidden"><Link to="">Lycanthrope: Formerly Human</Link></li>
            <li><Link to="makeunmake">Lycanthrope: Make/Unmake</Link></li>
            <li className="hidden"><Link to="">Lycanthrope: Power</Link></li>
            <li className="hidden"><Link to="">Lycanthrope: Wolf-Womb</Link></li>
            <li className="hidden"><Link to="">Mask</Link></li>
            <li><Link to="oldmemories">Old Memories</Link></li>
            <li><Link to="personalgrowth">Personal Growth After Dinner Parties</Link></li>
            <li className="hidden"><Link to="">Recursion</Link></li>
            <li><Link to="selfimmolation">Self-Immolation</Link></li>
            <li className="hidden"><Link to="">Sleep Paralysis</Link></li>
            <li className="hidden"><Link to="">Sleepless</Link></li>
            <li><Link to="subforlife">A Substitute for Life</Link></li>
          </ul>
          <p className="right"><Link to="colophon">Colophon</Link></p>
      </div>
    </Layout>
  )
}

export default IndexPage
